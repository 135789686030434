import { injectable } from "inversify";
import type { IEnvironment } from "./IEnvironment";

@injectable()
export class Environment implements IEnvironment {
  constructor() {}

  public getEnvironment(): "development" | "production" {
    return this.getVariable("VITE_IS_DEV") === "true" ? "development" : "production";
  }

  public getBackendUrl(): string {
    return this.getVariable("VITE_BACKEND_URL");
  }

  public getBackendUrlProtocol(): string {
    return this.getVariable("VITE_BACKEND_PROTOCOL");
  }

  private getVariable(variableName: string): string {
    let variable = import.meta.env[variableName];
    if(!variable) {
      return "false";
    }
    return variable;
  }
}